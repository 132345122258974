import React from 'react';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import { getSignupPath } from '../js';
import PricingSection from '../components/PricingSection';
import '@fontsource/roboto-condensed/700.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/kalam/400.css';
import '@fontsource/dm-serif-display/400.css';
import FastPacedIllus from '../images/illustrations/fast-paced.svg';
// import TickIcon from '../images/icons/tick_3d.svg';

export default function Top() {
  return (
    <Layout>
      <HeroSection />
      <ProblemStatement />
      <YouFirst />
      <Workflow />
      <Views />
      <JustEnough />
      <div className="mx-4 sm:mx-32 separator" />
      <PricingSection />
    </Layout>
  );
}

function HeroSection() {
  return (
    <section className="text-center pt-12 pb-32 sm:pt-20 hero-section px-12 sm:px-0">
      <h2 className="px-64 mx-16">Work management</h2>
      <h2 className="px-64 mx-16">for fast paced product teams</h2>
      <h4 className="mt-16">Intuitive. Effective. Stays out of the way.</h4>
      <div className="main-button-row mt-12">
        <a href={getSignupPath()}>
          <Button size="lg">Try Now</Button>
        </a>
      </div>
    </section>
  );
}

function YouFirst() {
  return (
    <section className="pt-8 sm:py-12 sm:px-36 value-section">
      <div className="sm:px-8 pb-8 sm:mx-32 flex flex-col">
        <h3 className="text-center">You first</h3>
        <h5 className="text-center">Collaboration meets personal productivity</h5>
        <div className="text-lg px-16 mt-8 text-justify">
          At WorkStudio, both personal productivity and team collaboration come together as one
          unified solution. The best part is how seamlessly they blend. A hybrid sharing model
          enables everyone to manage their work and run their day better, while collaborating with
          teammates.
          <br />
          <br />
          Private until shared - Privacy is freedom, privacy means control.
          <br />
          <br />
          Private tasks - Create tasks at will, be it for maintaining focus, or keeping a detailed
          work log. It's also great for creating quick drafts that you can come back to later,
          refine, and then share.
          <br />
          <br />
          Private tags - Organise things the way you need it; capture your perspective.
          <br />
          <br />
          Private milestones - Plan first, iterate, then share (or don’t) - you decide what to share
          with whom, when
          <br />
          <br />
          Facilitating personal productivity is not just a perk, it’s a need. When everyone has an
          incentive to keep things up-to-date (because it helps each one individually), things
          automatically stay up-to-date. In our survey we have found that if there is one thing
          managers get frustrated with, it’s continually motivating everyone to update their work
          items. High participation is the key to reliable state, which in turn helps everyone in
          better decision making and avoiding last minute chaos.
        </div>
      </div>
    </section>
  );
}

function ProblemStatement() {
  return (
    <section className="pt-8 sm:py-12 sm:px-36 value-section">
      <div className="sm:px-8 pb-8 sm:mx-32 flex flex-col gap-8">
        <h3 className="text-center">The problem we are solving</h3>
        <div className="text-lg px-16 text-justify">
          Existing solutions are rigid and represent work management problem poorly. They are built
          to help primarily the managers in tracking projects as opposed to helping everyone in
          managing their day to day.
          <br />
          <br />
          Overall, they drain energy, fail to incentivise participation, and leave much to be
          desired in terms of effectiveness, especially in fast paced environments.
          <br />
          <br />
          WorkStudio has a you-first approach to work management with a strong focus on flexibility,
          simplicity and universality.
        </div>
      </div>
    </section>
  );
}

function Workflow() {
  return (
    <section className="pt-8 sm:py-12 sm:px-36 value-section">
      <div className="sm:px-8 pb-8 sm:mx-32 flex flex-col gap-8">
        <h3 className="text-center">A workflow built with humans in mind</h3>
        <div className="text-lg px-16 text-justify">
          <h5 className="text-center">Organise → Plan → Schedule → Do</h5>
          <br />
          <br />
          WorkStudio gives you organisation system that ensures you never lose track of the tiniest
          of things, and leaves no room for ambiguity - all without enforcing any certain way of
          doing things. We believe in being truly agile as opposed to simply following a specific
          agile methodology.
          <br />
          <br />
          WorkStudio subscribes to a free-hand approach to work management and nothing is enforced
          by way of structures. We want you to do it your way, not ours. The workflow is simple,
          intuitive, and incredibly efficient for all four functions that a work management system
          serves: capturing work, planning, reviewing / tracking status, and maintaining focus.
        </div>
      </div>
    </section>
  );
}

function Views() {
  return (
    <section className="pt-8 sm:py-12 sm:px-36 value-section">
      <div className="sm:px-8 pb-8 sm:mx-32 flex flex-col gap-8">
        <h3 className="text-center">Views that answer what’s important</h3>
        <div className="text-lg px-40 text-justify">
          Me view - What's on my plate?
          <br />
          Milestone view - Whats going on with that initiative?
          <br />
          Tags view - What is what?
          <br />
          Team view - Who is doing what, when? coming soon
        </div>
      </div>
    </section>
  );
}

function JustEnough() {
  return (
    <section className="py-20 px-4 md:px-32 lg:py-32 our-focus-section">
      <div className="container mx-auto text-center">
        {/* <h5>Few things to update, always up to date</h5> */}
        <div className="px-32 mt-16">
          <div className="flex flex-col gap-24">
            <div className="flex flex-col sm:flex-row items-center">
              <div className="illus-container sm:w-2/5">
                <img src={FastPacedIllus} />
              </div>
              <div className="flex flex-col sm:w-3/5">
                <h5 className="text-center sm:text-left">Built for fast pace and agility</h5>
                {/* <h5 className="text-center sm:text-left">Built for fast pace and agility</h5> */}
                <div className="text-base mt-4 font-light leading-relaxed text-justify">
                  Complex interfaces and rigid structures slow us down. WorkStudio is built ground
                  up for fast pace and agility, while doing away with the complexity and rigidity.
                  Every aspect - creating, updating, filtering, scanning, or switching views - is
                  built to be quick and easy.
                  <br />
                  <br />
                  If you are in an environment where things are really dynamic and changing
                  priorities and interrupts are the norm, WorkStudio is a must try.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Card(props) {
  const { className = '', children, title } = props;

  return (
    <div
      className={`${className} text-left flex flex-col justify-center py-8 relative info-block rounded-md w-full px-8`}
    >
      {title ? <p className="font-semibold text-2xl mb-6">{title}</p> : null}
      <div className="text-base text-justify">{children}</div>
      {/* <div className="absolute top-3 -left-6">
        <img src={TickIcon} />
      </div> */}
    </div>
  );
}

function Hl(props) {
  const { children } = props;
  return <span className="font-semibold">{children}</span>;
}
