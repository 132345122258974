import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { getSignupPath } from '../js';
import Button from './Button';

export default function PricingSection() {
  const starterFeatureList = [];

  const proFeatureList = [];

  return (
    <section id="pricing" className="pt-20 sm:pb-20 lg:py-32 sm:px-56 pricing-section">
      <div className="flex flex-col items-center">
        <div className="text-center mb-24">
          <h3>Pricing</h3>
          <p className="text-2xl mt-12">
            Simple, <span className="">all inclusive</span> pricing
          </p>
          <div className="flex flex-col sm:flex-row md:mx-8 lg:gap-16 justify-center mt-20">
            <PricingCard
              className="starter"
              name="Free"
              featureList={starterFeatureList}
              price={0}
              id="starter"
            />
            <PricingCard
              className="pro"
              name="Pro"
              featureList={proFeatureList}
              price={5}
              id="pro"
            />
          </div>
        </div>
        <a href={`${getSignupPath()}?plan=starter`}>
          <Button className="py-4 text-xl">Let's Get Started</Button>
        </a>
      </div>
    </section>
  );
}

function PricingCard(props) {
  const { className, name, price, id } = props;
  return (
    <div
      className={`relative px-10 py-12 md:mx-8 sm:rounded-lg border-gray-200 md:h-full ${className} pricing-card`}
    >
      <h4>{name}</h4>
      <div className="flex flex-row items-end justify-center mb-4">
        <div className="font-light text-2xl mb-0.5">$</div>
        <div className="text-4xl lg:text-5xl">{price}</div>
      </div>
      <div className="text-center italic pricing-type">
        {price ? '/ user / month' : 'Upto 5 users'}
      </div>
      <div className="absolute decorator" />
    </div>
  );
}
